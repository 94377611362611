import BottomNav from './BottomNav'
import Container from './Container'
import TopNav from './TopNav'
import { usePage } from '@inertiajs/inertia-react'

export default function Header() {
  const { url } = usePage()

  if (
    url === '/sell' ||
    url === '/sign-in' ||
    url === '/sign-up' ||
    url === '/forgot-password'
  ) {
    return null
  }

  return (
    <Container className="">
      <nav aria-label="Navigation bar" className="">
        <div className="bg-grayLight" aria-label="Secondary navigation">
          <TopNav />
        </div>

        <div
          aria-label="Main navigation bar"
          className="bg-white border-b border-grayLight"
        >
          <BottomNav />
        </div>
      </nav>
    </Container>
  )
}
