import React, { useEffect } from 'react'
import { Cancel01Icon } from '@hugeicons/react'
import { Link } from '@inertiajs/inertia-react'
import {
  about_path,
  cars_path,
  curated_search_path,
  peach_process_path,
  resources_path,
  sell_path,
} from '../../routes'

interface MobileMenuProps {
  isOpen: boolean
  onClose: () => void
}

export default function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  return (
    <section
      className={`fixed inset-0 bg-gray-700 bg-opacity-30 z-[1000] md:hidden transition-opacity duration-500 ease-in-out ${
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      }`}
    >
      <div className="flex flex-col justify-end items-end px-5 h-full">
        <div
          className={`min-h-[23rem] bg-white w-full rounded-[1rem] py-12 px-8 transform transition-transform duration-500 ease-in-out ${
            isOpen ? 'translate-y-0' : 'translate-y-full'
          }`}
        >
          <div className="flex justify-end">
            <Cancel01Icon
              size={20}
              color={'#EF4444'}
              variant={'twotone'}
              onClick={onClose}
            />
          </div>
          <div className="space-y-3 text-[1.3rem] text-textGray mt-4">
            <Link className="block" href={cars_path()} onClick={onClose}>
              Buy a Car
            </Link>
            <Link className="block" href={sell_path()} onClick={onClose}>
              Sell a Car
            </Link>
            <Link
              className="block"
              href={curated_search_path()}
              onClick={onClose}
            >
              Curate your search
            </Link>
            <Link
              className="block"
              href={peach_process_path()}
              onClick={onClose}
            >
              Peach Processes
            </Link>
            <Link className="block" href={resources_path()} onClick={onClose}>
              Resources
            </Link>
            <Link className="block" href={about_path()} onClick={onClose}>
              About Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
