import { ReactNode, useEffect, useState } from 'react'
import Container from '../components/layout/Container'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import '../assets/css/styles.css'
import { usePage } from '@inertiajs/inertia-react'
import { RoutingProvider } from '../contexts/RoutingContext'

const Layout = ({ children }: any) => {
  const { url } = usePage()
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (url.includes('curated-search')) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }, [url])

  return (
    <RoutingProvider>
      <Container>
        <div className={isSticky ? 'sticky top-0 z-[50]' : ''}>
          <Header />
        </div>

        <Container>
          <main className="min-h-[calc(100vh-12.6rem)] bg-white font-satoshi">
            {children}
          </main>
        </Container>

        <Footer />
      </Container>
    </RoutingProvider>
  )
}

export default (page: ReactNode) => <Layout>{page}</Layout>
