import Container from './Container'
import logo from '@/assets/images/logo.svg'
import logo_long from '@/assets/images/logo_named.png'
import { Link, usePage } from '@inertiajs/inertia-react' // Import usePage hook
import { FavouriteIcon, Menu11Icon } from '@hugeicons/react'
import { useEffect, useState } from 'react'
import MobileMenu from './MobileMenu'
import {
  about_path,
  cars_path,
  curated_search_path,
  peach_process_path,
  resources_path,
  sell_path,
  wishlist_path,
} from '../../routes'

export default function BottomNav() {
  const [iconSize, setIconSize] = useState(14)
  const [isOpen, setIsOpen] = useState(false)
  const { url } = usePage()

  useEffect(() => {
    const updateSize = () => {
      const screenWidth = window.innerWidth
      if (screenWidth >= 1024) {
        setIconSize(14)
      } else if (screenWidth >= 768) {
        setIconSize(14)
      } else if (screenWidth >= 640) {
        setIconSize(20)
      } else {
        setIconSize(20)
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <Container size="centered" className="px-6 h-full font-normal md:px-0">
      <section
        aria-label="Bottom main navigation bar"
        className="flex items-center h-[6.1rem]"
      >
        <article className=" pr-[7rem]" aria-label="logo">
          <Link href="/">
            <img
              src={logo}
              alt="Logo"
              className="w-10 hidden sm:block md:hidden h-[3.5rem] w-[2.8rem]"
            />
          </Link>
          <Link href="/">
            <img src={logo_long} alt="Logo" className="sm:hidden h-[3.5rem] " />
          </Link>
          <Link href="/">
            <img
              src={logo_long}
              alt="Logo"
              className=" hidden md:block h-[4rem] "
            />
          </Link>
        </article>
        <article
          className="flex items-center justify-between text-[1.3rem] text-textGray flex-1 "
          aria-label="Navigation links"
        >
          <div
            className="flex invisible gap-6 items-center md:visible"
            role="navigation"
            aria-label="Main navigation"
          >
            <Link
              href={cars_path()}
              className={`nav-link ${url === '/cars' ? '!text-pinkDark' : ''}`}
              aria-label="Buy a car page"
            >
              Buy a Car
            </Link>
            <Link
              href={sell_path()}
              className={`hidden md:inline-flex nav-link ${url === '/sell' ? '!text-pinkDark' : ''}`}
              aria-label="Sell a car page"
            >
              Sell a Car
            </Link>
            <Link
              href={curated_search_path()}
              className={`hidden md:inline-flex nav-link ${url === '/curated-search' ? '!text-pinkDark' : ''}`}
              aria-label="Sell a car page"
            >
              Curate your Search
            </Link>
            {/*    <Link
              href="/inspection"
              className={`hidden md:inline-flex nav-link ${url === '/inspection' ? '!text-pinkDark' : ''}`}
              aria-label="Get inspection page"
            >
              Get Inspection
            </Link> */}
          </div>
          <div
            className="gap-6 items-center md:flex"
            role="navigation"
            aria-label="Secondary nav links"
          >
            <Link
              href={peach_process_path()}
              className={`hidden md:inline-flex nav-link ${url === '/peach_process' ? '!text-pinkDark' : ''}`}
              aria-label="Peach processes a car page"
            >
              Peach Processes
            </Link>
            <Link
              href={resources_path()}
              className={`hidden md:inline-flex nav-link ${url === '/resources' ? '!text-pinkDark' : ''}`}
              aria-label="Resourceful links a car page"
            >
              Resources
            </Link>
            <Link
              href={about_path()}
              className={`hidden md:inline-flex nav-link ${url === '/about' ? '!text-pinkDark' : ''}`}
              aria-label="About us page"
            >
              About Us
            </Link>
            <Link
              href={wishlist_path()}
              className={`inline-flex items-center justify-center group gap-3 nav-link ${url === '/wishlist' ? '!text-pinkDark' : ''}`}
            >
              <div className="md:pb-0.5">
                <FavouriteIcon
                  size={iconSize}
                  color={url === '/wishlist' ? '#EE3877' : '#1F2937'}
                  variant={'twotone'}
                  className="transition ease-in-out group-hover:text-pinkDark"
                />
              </div>

              <span
                className="hidden md:inline-flex"
                aria-label="Get inspection page"
              >
                Wishlist
              </span>
            </Link>
            <div
              className="inline-flex pl-6 md:hidden"
              onClick={() => setIsOpen(true)}
            >
              <Menu11Icon size={20} color={'#1F2937'} variant={'twotone'} />
            </div>
          </div>
        </article>
      </section>
      <MobileMenu isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Container>
  )
}
