import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import { usePage } from '@inertiajs/inertia-react'
import { Inertia } from '@inertiajs/inertia'

type RoutingContextType = {
  history: string[]
  addHistory: (url: string) => void
}

const RoutingContext = createContext<RoutingContextType | undefined>(undefined)

export const useRouting = (): RoutingContextType => {
  const context = useContext(RoutingContext)
  if (!context) {
    throw new Error('useRouting must be used within a RoutingProvider')
  }
  return context
}

export const RoutingProvider = ({ children }: { children: ReactNode }) => {
  const [history, setHistory] = useState<string[]>([])

  const addHistory = (url: string) => {
    setHistory(prevHistory => [...prevHistory, url])
  }

  useEffect(() => {
    Inertia.on('navigate', event => {
      const url = event.detail.page.url
      addHistory(url)
    })

    return () => {
      Inertia.off('navigate')
    }
  }, [])

  return (
    <RoutingContext.Provider value={{ history, addHistory }}>
      {children}
    </RoutingContext.Provider>
  )
}
